<template>
  <div class="box">
    <div class="content login">
      <div class="top">
        <div>新用户注册</div>
        <!-- <div class="top_rt">
          <div>如未注册，点此</div>
          <div>注册</div>
        </div> -->
      </div>
      <div class="zhong">
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/ren.png" alt="" />
            账号
          </div>
          <input
            class="intlogin"
            v-model.trim="username"
            type="text"
            placeholder="请输入您的账号"
          />
        </div>
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/mi.png" alt="" />
            密码
          </div>
          <input
            class="intlogin"
            type="password"
            v-model.trim="password"
            placeholder="请输入您的密码"
          />
        </div>
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/mi.png" alt="" />
            密码
          </div>
          <input
            class="intlogin"
            type="password"
            v-model.trim="passwordagain"
            placeholder="请再次输入您的密码"
          />
        </div>
        <div class="user">
          <div class="yanzhengma_lg_img">
            <img @click="verify" class="yanzhengma_lg_img" :src="verify1" alt />
          </div>
          <input
            class="intlogin"
            type="text"
            v-model.trim="captcha"
            placeholder="请输入验证码"
          />
        </div>
        <div class="user_type">
          <div class="img_box_type">用户类型</div>
          <el-radio-group v-model="type">
            <el-radio :label="1">个人</el-radio>
            <el-radio :label="2">公司</el-radio>
            <el-radio :label="3">院校</el-radio>
          </el-radio-group>
        </div>
        <div class="user1">
          <img class="img1" src="../assets/img/icon_qx@2x.png" alt="" />
          <div class="box_xieyi">我已看过并接受《<span>用户协议</span>》</div>
        </div>
        <div class="denglu" @click="register">同意协议并注册</div>
        <div @click="gologin" class="top_rt1">如已注册，点此登录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      passwordagain: '',
      phone: '',
      verify1: '',
      type: 2,
      captcha: '',
    }
  },
  // 页面加载完成之后调用的函数
  mounted() {
    this.verify()
  },
  methods: {
    async verify() {
      this.verify1 = this.$URL + 'index.php/api/index/verify?r=' + Math.random()
      this.__token__()
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    async register() {
      if (this.username.length <= 0) {
        return this.$message.error('请输入您的用户名')
      }
      if (this.password.length <= 0) {
        return this.$message.error('请输入您的密码')
      }
      if (this.passwordagain.length <= 0) {
        return this.$message.error('请再次输入您的密码')
      }
      if (this.captcha.length <= 0) {
        return this.$message.error('请输入您的验证码')
      }
      const { data: res } = await this.$http.post('edulogin.php/index/register', {
        username: this.username,
        password: this.password,
        passwordagain: this.passwordagain,
        captcha: this.captcha,
        type: this.type,
        __token__: this.token,
      })
      console.log(111, res)
      if (res.code != 1) {
        this.token = res.data.__token__
        this.verify()
        this.captcha = ''
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
    },
    gologin() {
      this.$router.push('/login')
    }
  },
}
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  padding-top: 46px;
  box-sizing: border-box;
  .login {
    height: 700px;
    background-color: #fff;
    border-radius: 12px;
    .top {
      width: 100%;
      height: 61px;
      border-bottom: 1px solid #ddd;
      text-align: center;
      line-height: 61px;
      position: relative;
      font-size: 20px;
      color: #134daf;
      .top_rt {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #666;
        div:nth-child(2) {
          width: 65px;
          height: 36px;
          background: #134daf;
          border-radius: 4px;
          line-height: 36px;
          color: #fff;
          margin: 0 20px 0 10px;
        }
      }
    }
    .zhong {
      width: 480px;
      margin: 73px auto 0;
      .user {
        display: flex;
        width: 480px;
        height: 50px;
        border: 1px solid #ddd;
        margin-bottom: 30px;
        .img_box {
          display: flex;
          width: 94px;
          border-right: 1px solid #ddd;
          align-items: center;
          font-size: 16px;
          color: #333;
          img {
            width: 26px;
            height: 26px;
            display: block;
            margin: 0 6px;
          }
        }
        .yanzhengma_lg_img {
          width: 94px;
          height: 100%;
        }
        .intlogin {
          width: 350px;
          border: none;
          padding-left: 10px;
        }
        input {
          outline: none;
        }
      }
      .user_type {
        display: flex;
        align-items: center;
        .img_box_type {
          width: 94px;
          text-align: center;
        }
      }

      .user1 {
        display: flex;
        margin-top: 20px;
        .img1 {
          width: 20px;
          height: 20px;
          display: block;
          margin-right: 10px;
        }
        .box_xieyi {
          color: #333;
          span {
            color: #134daf;
          }
        }
      }
      .denglu {
        width: 480px;
        height: 50px;
        background: #e85050;
        border: 1px solid #e50e0e;
        border-radius: 3px;
        line-height: 51px;
        text-align: center;
        color: #ffffff;
        margin-top: 35px;
        cursor: pointer;
      }
      .top_rt1 {
        padding-top: 15px;
        font-size: 14px;
        // color: #fff;
        cursor: pointer;
      }
    }
  }
   
}
</style>
